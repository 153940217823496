import { AxiosRequestConfig } from 'axios';
import { parseHeaders } from '../Context/GlobalState';
import { ApiEndpoints } from '../http/ApiEndpoints';
import { http } from '../http/Http';
import { IInvoiceReadView } from './Interfaces';

interface IInvoicesResponse {
  invoices: IInvoiceReadView[];
  totalCount: number;
}

const { CustomersUrl } = ApiEndpoints;

export const fetchInvoiceById = async (id: string, config?: AxiosRequestConfig): Promise<IInvoiceReadView> => {
  const { data } = await http.get<IInvoiceReadView>(`${ApiEndpoints.Invoices}/${id}`, config);
  return data;
};

export const fetchCustomerInvoices = async (id: string, config?: AxiosRequestConfig): Promise<IInvoicesResponse> => {
  const { data, headers } = await http.get<IInvoiceReadView[]>(`${CustomersUrl}/${id}/invoices`, config);
  const { totalCount } = parseHeaders(headers);
  return { invoices: data, totalCount };
};
