import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const ComponentLoader: React.FC<{}> = () => (
  <Dimmer active={true}>
    <Loader active={true} inline={true} size="large" />
  </Dimmer>
);

export default ComponentLoader;
