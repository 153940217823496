import React from 'react';
import {
  applicationsDefaultTableFilters,
  IApplicationsTableFilters,
  LoanStatusDisplay,
  ApplicationsSortOptions
} from './Interfaces';
import { PaginationProps } from 'semantic-ui-react';
import { defaultLimit } from '../TableActions/DefaultValues';
import { Outlet } from 'react-router';

type StatusFilters = { [key in LoanStatusDisplay]: IApplicationsTableFilters };

/**  filters for each status.
 * each status initially assigned with default filters.
 * later in the context change of filters affect only that status
 * @scenario in status `INKASSO` + show archived, we have searched for `Jonas` which resulted in 60 records (3 page in pagination with limit 20 rows per page)
 * and user navigated to 3rd page (offset 40). Now we change status to `PAYED`,
 * search phrase is still `Jonas` which results in 0 records, so offset 40 will result in fetch error.
 *
 * So search phrase, is shared between statuses, but the rest of filters (sort,offset,pagination,...) are status specific.
 */
const initialStatusFilters: StatusFilters = {
  NONE: { ...applicationsDefaultTableFilters, status: 'NONE' },
  CANCELLED: { ...applicationsDefaultTableFilters, status: 'CANCELLED' },
  INKASSO: { ...applicationsDefaultTableFilters, status: 'INKASSO' },
  INKASSO_SOLD: { ...applicationsDefaultTableFilters, status: 'INKASSO_SOLD' },
  INKASSO_KRONOFOGDEN: { ...applicationsDefaultTableFilters, status: 'INKASSO_KRONOFOGDEN' },
  INVOICED: { ...applicationsDefaultTableFilters, status: 'INVOICED' },
  LOCKED: { ...applicationsDefaultTableFilters, status: 'LOCKED' },
  OFFERED: { ...applicationsDefaultTableFilters, status: 'OFFERED' },
  PAID_OFF: { ...applicationsDefaultTableFilters, status: 'PAID_OFF' },
  PAYED: { ...applicationsDefaultTableFilters, status: 'PAYED' },
  NEEDS_REVIEW: { ...applicationsDefaultTableFilters, status: 'NEEDS_REVIEW' },
  PENDING: { ...applicationsDefaultTableFilters, status: 'PENDING' },
  REGISTERED: { ...applicationsDefaultTableFilters, status: 'REGISTERED' },
  AWAITING_PAYOUT: { ...applicationsDefaultTableFilters, status: 'AWAITING_PAYOUT' },
  REJECTED: { ...applicationsDefaultTableFilters, status: 'REJECTED' },
  REMINDER: { ...applicationsDefaultTableFilters, status: 'REMINDER' }
};

export interface IApplicationsContextProps {
  statusSpecific: StatusFilters;
  setStatusSpecific: React.Dispatch<React.SetStateAction<StatusFilters>>;
  currentFilters: IApplicationsTableFilters;
  setCurrentFilters: React.Dispatch<React.SetStateAction<IApplicationsTableFilters>>;
  currentStatus: LoanStatusDisplay;
  setCurrentStatus: React.Dispatch<React.SetStateAction<LoanStatusDisplay>>;
  handleArchiveChange: (showArchive: boolean) => void;
  onLimitChanged: (changedLimit: number) => void;
  onPageChanged: (e: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => void;
  onSortOrderChanged: (sort: ApplicationsSortOptions) => void;
  handleStatusChange: (status: LoanStatusDisplay, archive?: boolean) => void;
  searchInApplications: (filter: string) => void;
  finalizeSearch: (id: string, filter: string) => void;
  switchStatus: (status: LoanStatusDisplay) => void;
}

export const ApplicationsContext = React.createContext<IApplicationsContextProps>({
  statusSpecific: initialStatusFilters,
  setStatusSpecific: () => {},
  currentFilters: applicationsDefaultTableFilters,
  setCurrentFilters: () => {},
  currentStatus: applicationsDefaultTableFilters.status,
  setCurrentStatus: () => {},
  handleArchiveChange: (showArchive: boolean) => {},
  onLimitChanged: (changedLimit: number) => {},
  onPageChanged: (e: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {},
  onSortOrderChanged: (sort: ApplicationsSortOptions) => {},
  handleStatusChange: (status: LoanStatusDisplay, archive?: boolean) => {},
  searchInApplications: (filter: string) => {},
  finalizeSearch: (id: string, filter: string) => {},
  switchStatus: (status: LoanStatusDisplay) => {}
});

const ApplicationsFiltersContext: React.FC = () => {
  const [currentFilters, setCurrentFilters] = React.useState<IApplicationsTableFilters>(
    applicationsDefaultTableFilters
  );
  const [currentStatus, setCurrentStatus] = React.useState<LoanStatusDisplay>(applicationsDefaultTableFilters.status);
  const [statusSpecific, setStatusSpecific] = React.useState<StatusFilters>(initialStatusFilters);

  React.useEffect(() => {
    setCurrentFilters(statusSpecific[currentStatus]);
  }, [currentStatus, statusSpecific]);

  /** Callback function when showArchive changes. On topStatus or dropdown */
  const handleArchiveChange = (showArchive: boolean) => {
    setStatusSpecific({
      ...statusSpecific,
      [currentStatus]: { ...statusSpecific[currentStatus], showArchived: showArchive }
    });
  };

  /** the callback function when the number of rows in page is changed e.g. 20 av totalt */
  const onLimitChanged = (changedLimit: number) => {
    const offsetToBe = Math.floor(currentFilters.offset / changedLimit) * changedLimit;
    setStatusSpecific({
      ...statusSpecific,
      [currentStatus]: { ...statusSpecific[currentStatus], limit: changedLimit, offset: offsetToBe }
    });
  };

  /** applies the offset (the page number in pagination component) to filters object */
  const onPageChanged = (e: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => {
    const currentLimit = currentFilters.limit || defaultLimit;
    const offsetToBe = ((data.activePage as number) - 1) * currentLimit;
    setStatusSpecific({
      ...statusSpecific,
      [currentStatus]: { ...statusSpecific[currentStatus], offset: offsetToBe }
    });
  };

  const onSortOrderChanged = (sort: ApplicationsSortOptions) => {
    const sortAscending = !currentFilters.sortAscending;
    setStatusSpecific({
      ...statusSpecific,
      [currentStatus]: { ...statusSpecific[currentStatus], sort, sortAscending }
    });
  };

  /** Callback function when status changes. On topStatus or dropdown
   * by passing optional show archive you can directly display archived data of that status
   */
  const handleStatusChange = (status: LoanStatusDisplay, showArchived?: boolean, needsMandate?: boolean) => {
    if (showArchived) {
      setStatusSpecific({
        ...statusSpecific,
        [currentStatus]: { ...statusSpecific[currentStatus], showArchived }
      });
    }
    if (needsMandate) {
      setStatusSpecific({
        ...statusSpecific,
        [currentStatus]: { ...statusSpecific[currentStatus], needsMandate }
      });
    }
    setCurrentStatus(status ? status : 'NONE');
  };

  /** Applies the search phrase (here SSN/name/phonenumber) to filters object */
  const searchInApplications = (filter: string) => {
    // now we need this search phrase to be the filter for all statuses
    const replaced = replaceStatusFilters(filter);
    setStatusSpecific(replaced);
  };

  const finalizeSearch = (id: string, filter: string) => {
    const replaced = replaceStatusFilters(filter);
    setStatusSpecific(replaced);
  };

  const switchStatus = (status: LoanStatusDisplay) => {
    setCurrentStatus(status);
  };

  const replaceStatusFilters = (filter: string): StatusFilters => {
    return JSON.parse(JSON.stringify(statusSpecific), (key, value) => {
      if (key === 'filter') {
        return filter;
      } else if (key === 'offset') {
        return 0;
      } else {
        return value;
      }
    });
  };

  return (
    <ApplicationsContext.Provider
      value={{
        statusSpecific,
        setStatusSpecific,
        currentFilters,
        setCurrentFilters,
        currentStatus,
        setCurrentStatus,
        handleArchiveChange,
        onLimitChanged,
        onPageChanged,
        onSortOrderChanged,
        handleStatusChange,
        searchInApplications,
        finalizeSearch,
        switchStatus
      }}
    >
      <Outlet />
    </ApplicationsContext.Provider>
  );
};

export default ApplicationsFiltersContext;
