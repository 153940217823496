export enum ApiEndpoints {
  CustomersUrl = 'customers',
  UsersUrl = 'users',
  SnabbloanUrl = 'applications-snabb',
  SnabbloanStatusStatistics = 'applications-snabb-stats',
  BlockedSsnsUrl = 'blocked-ssns',
  BlockedPhoneUrl = 'blocked-phone-numbers',
  BlockedBankAccUrl = 'blocked-bank-accounts',
  CachedBlockedSsnsUrl = 'cached-blocked-ssns',
  CachedSSnsUrl = 'cached-ssns',
  MessagesUrl = 'messages',
  IncomingPaymentsUrl = 'payments/incoming',
  IncomingIsoPaymentsUrl = 'payments/incoming-iso',
  OutgoingPaymentsUrl = 'payments/outgoing',
  AutogiroFilesUrl = 'autogiro/files',
  ReportsUrl = 'reports',
  ReportDebtCollectionFilesUrl = 'reports/debt-collection-files',
  ReportConsumerCreditSurveyUrl = 'reports/swedishConsumerCredit',
  VerificationRulesUrl = 'verification-rules',
  CommonExpensesUrl = 'common-expenses',
  ExpensesYearUrl = 'expenses-years',
  PersonExpensesUrl = 'person-expenses',
  versionController = 'version',
  CreditChecks = 'credit-checks',
  Invoices = 'invoices',
  SettingsUrl = 'settings',
  TermsUrl = 'terms/consumer-credit-agreement-sv',
  FortnoxOauthUrl = '../../../fortnox-oauth/actions'
}
