export const defaultLimit: number = 20;

export type BasicSortOption = 'id';

export type Gender = 'MALE' | 'FEMALE';

export interface IBasicTableFilters {
  sortAscending: boolean;
  filter: string;
  limit: number;
  offset: number;
}

export interface PaginationFilters {
  limit: number;
  offset: number;
}

export const defaultPaginationFilters: PaginationFilters = {
  limit: defaultLimit,
  offset: 0
};
export interface IDropDownOption {
  value: string;
  text: string;
}
