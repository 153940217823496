import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useEffect } from 'react';
import {
  Icon,
  Label,
  Search,
  SearchCategoryProps,
  SearchProps,
  SearchResultData,
  SearchResultProps
} from 'semantic-ui-react';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { fetchCustomers } from '../Customers/CustomersService';
import { InvoiceStatusColor, InvoiceStatusMessage } from '../Invoices/Interfaces';
import { customerDefaultTableFilters, ICustomerTableFilters } from '../Customers/Interfaces';
import { CancelToken } from '../http/Http';
import { fetchInvoiceById } from '../Invoices/InvoiceService';
import { LoanStatusColor, LoanStatusIcon, LoanStatusMessage } from '../Applications/Interfaces';
import { fetchApplication } from '../Applications/ApplicationsService';
import { formatDate } from './Formatters';
import './GlobalSearch.scss';
import { useNavigate } from 'react-router';

export interface PathSearchCategoryProps extends SearchCategoryProps {
  [path: string]: any;
}
export interface IconStatus {
  color: SemanticCOLORS;
  icon: SemanticICONS;
  status: string;
}
export interface GlobalSearchResultProps extends SearchResultProps {
  label?: string;
  status?: IconStatus;
}

const initialResult: PathSearchCategoryProps = {
  customer: {
    name: 'Kund',
    path: '/customer/',
    results: []
  },
  application: {
    name: 'Ansökan',
    path: '/applications/view/',
    results: []
  },
  invoice: {
    name: 'Faktura',
    path: '/invoice/',
    results: []
  }
};

const resultRenderer = ({ id, description, label, title, status }: GlobalSearchResultProps) => (
  <div key={id}>
    <div className="label">
      <Label>
        <Icon name="user" />
        {label}
      </Label>
    </div>
    <div className="title">
      {title}
      {status && (
        <span className="status">
          <Icon inverted name={status.icon} color={status.color} />
          {status.status}
        </span>
      )}
    </div>
    <div className="description">{description}</div>
  </div>
);

const GlobalSearch: React.FC = () => {
  let navigate = useNavigate();
  const searchRef = React.createRef<HTMLInputElement>();
  const [isActive, setIsActive] = React.useState<boolean>(false);

  const [value, setValue] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [results, setResults] = React.useState<PathSearchCategoryProps>([]);

  React.useEffect(() => {
    const cancelTokenSource = CancelToken.source();
    if (!value || (value && value.length === 0)) {
      setResults([]);
      setLoading(false);
      return;
    }
    (async () => {
      const fetchData = async () => {
        setLoading(true);
        let categorizedResult: PathSearchCategoryProps = initialResult;
        let customersResult: GlobalSearchResultProps[] = [];
        let applicationResult: GlobalSearchResultProps[] = [];
        let invoiceResult: GlobalSearchResultProps[] = [];

        try {
          const filter = value;
          const params: ICustomerTableFilters = { ...customerDefaultTableFilters, limit: 5, filter };

          let { customers: allCustomers } = await fetchCustomers({
            params,
            cancelToken: cancelTokenSource.token
          });

          allCustomers = allCustomers.filter((customer) => isNaN(parseInt(customer.fullName) as any));

          customersResult = allCustomers.map((customer) => ({
            title: customer.fullName,
            description: 'Personnr: ' + customer.ssn,
            label: 'Kund ' + customer.id.toString(),
            key: customer.id
          }));
          categorizedResult.customer.results = customersResult;

          if (filter.match(/^\s*[0-9]+\s*$/) !== null) {
            try {
              let application = await fetchApplication(filter);
              applicationResult = [
                {
                  title: application.id.toString(),
                  status: {
                    icon: LoanStatusIcon[application.loanStatus],
                    color: LoanStatusColor[application.loanStatus],
                    status: LoanStatusMessage[application.loanStatus]
                  },
                  description: 'Ansökt: ' + formatDate(application.createdOrApplied),
                  label: 'Kund ' + application.customer.id,
                  key: application.id
                }
              ];
              categorizedResult.application.results = applicationResult;
            } catch (error) {
              categorizedResult.application.results = [];
            }
            try {
              let invoice = await fetchInvoiceById(filter);
              invoiceResult = [
                {
                  title: invoice.id.toString(),
                  status: {
                    icon: 'circle',
                    color: InvoiceStatusColor[invoice.invoiceStatus],
                    status: InvoiceStatusMessage[invoice.invoiceStatus]
                  },
                  description:
                    'Förfaller: ' + formatDate(invoice.dueDate) + ' Fortnox: ' + invoice.fortnoxInvoiceNumber,
                  label: 'Kund ' + invoice.customerId.toString(),
                  key: invoice.id
                }
              ];
              categorizedResult.invoice.results = invoiceResult;
            } catch (error) {
              categorizedResult.invoice.results = [];
            }
          } else {
            categorizedResult.application.results = [];
            categorizedResult.invoice.results = [];
          }
        } catch (error) {
          categorizedResult = [];
        }

        if (categorizedResult) {
          categorizedResult = emptyWhenNoResult(categorizedResult);
        }
        return categorizedResult;
      };

      const debouncedFetch = AwesomeDebouncePromise(fetchData, 500);
      try {
        const response = await debouncedFetch();
        setResults(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setResults([]);
      }
    })();
    return () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel('Operation canceled due to new request.');
      }
      setLoading(false);
    };
  }, [value]);

  const emptyWhenNoResult = (result: PathSearchCategoryProps) => {
    for (const [key] of Object.entries(result)) {
      if (result[key].results.length > 0) {
        return result;
      }
    }
    return [];
  };

  const handleSearchChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, data: SearchProps) => {
    setValue(data.value);
  };

  const handleResultSelect = (e: React.MouseEvent, data: SearchResultData) => {
    let fullSelection: PathSearchCategoryProps = Object.values(data.results!).filter((category) =>
      category.results.includes(data.result)
    );
    navigate(`${fullSelection[0].path}${data.result.key}`);
  };

  const setFocus = useCallback(
    (event: any) => {
      if (event.key === '/' && !isActive) {
        event.preventDefault();
        searchRef.current?.focus();
      }
    },
    [isActive, searchRef]
  );

  useEffect(() => {
    document.addEventListener('keydown', setFocus, false);
    return () => {
      document.removeEventListener('keydown', setFocus, false);
    };
  }, [setFocus]);

  useEffect(() => {
    searchRef.current?.focus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Search
      input={{ ref: searchRef }}
      className="globalSearch"
      category
      loading={loading}
      placeholder="Sök"
      resultRenderer={resultRenderer}
      onSearchChange={handleSearchChange}
      onResultSelect={handleResultSelect}
      results={results}
      value={value}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      showNoResults={false}
    />
  );
};

export default GlobalSearch;
