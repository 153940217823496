import { format, getTime } from 'date-fns';
import { sv } from 'date-fns/locale';

const dateTimeFormatter = (input: number, dateFormat: string): string => {
  if (!input) return '';
  const date = new Date(input);
  const formattedDateTime = format(date, dateFormat, { locale: sv });
  return formattedDateTime;
};

export const formatDate = (input: number): string => {
  return dateTimeFormatter(input, 'yyyy-MM-dd');
};

export const formatDateTime = (input: number): string => {
  return dateTimeFormatter(input, 'yyyy-MM-dd HH:mm');
};

export const formatTime = (input: number): string => {
  return dateTimeFormatter(input, 'HH:mm');
};

export const convertToMilliseconds = (input: Date): number => {
  const mls = getTime(input);
  return mls;
};

export const convertToDate = (input: number): Date => {
  const date = new Date(input);
  return date;
};

export const formatMoney = (input: number): string => {
  return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK', maximumFractionDigits: 0 }).format(input);
};

export const formatMonths = (input: number): string => {
  const word: string = input === 1 ? 'månad' : 'månader';
  return `${input} ${word}`;
};

export const truncateString = (string: string, maxLength: number): string => {
  if (string.length <= maxLength) {
    return string;
  } else {
    return string.slice(0, maxLength) + '...';
  }
};
