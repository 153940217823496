import { AxiosRequestConfig } from 'axios';
import { ApiEndpoints } from '../http/ApiEndpoints';
import { http, HttpStatusCode } from '../http/Http';
import { parseHeaders } from '../Context/GlobalState';
import {
  ICustomerReadView,
  CustomerUpdateView,
  ITransactionReadView,
  SSNReadView,
  CustomerAuthentication
} from './Interfaces';
import {
  ICreditsafeReadView,
  ILoanApplicationSnabbReadView,
  ISnabbApplicationsResponse
} from '../Applications/Interfaces';
import { DownloadFileResponse } from '../Reports/Interfaces';
import { getFileNameFromHeaders } from '../utils/FileDownloader';
import { AutogiroMandate } from '../Payments/Autogiro/AutogiroInterfaces';

const { CustomersUrl, CachedSSnsUrl, CreditChecks, AutogiroFilesUrl } = ApiEndpoints;

interface ICustomersResponse {
  customers: ICustomerReadView[];
  totalCount: number;
}

interface SSNsResult {
  ssns: SSNReadView[];
  totalCount: number;
}

export const fetchCustomers = async (config?: AxiosRequestConfig): Promise<ICustomersResponse> => {
  const { data, headers } = await http.get<ICustomerReadView[]>(CustomersUrl, config);
  const { totalCount } = parseHeaders(headers);

  return { customers: data, totalCount };
};

export const fetchCustomer = async (id: string | number, config?: AxiosRequestConfig): Promise<ICustomerReadView> => {
  const { data } = await http.get<ICustomerReadView>(`${CustomersUrl}/${id}`, config);
  return data;
};

export const fetchCreditSafeBySSN = async (ssn: string, config?: AxiosRequestConfig): Promise<ICreditsafeReadView> => {
  const { data } = await http.get<ICreditsafeReadView>(`${CreditChecks}/${ssn}`, config);
  return data;
};

interface IStatus {
  status: number;
}

export interface ICachedSSNParams {
  limit: number;
  ssn: string;
}
export const updateCustomer = async (id: number, entity: CustomerUpdateView): Promise<IStatus> => {
  const { status } = await http.patch<CustomerUpdateView>(`${CustomersUrl}/${id}`, entity);
  return { status };
};

export const fetchSuggestedSSNs = async (config: AxiosRequestConfig): Promise<SSNsResult> => {
  const { data, headers } = await http.get<SSNReadView[]>(CachedSSnsUrl, config);
  const { totalCount } = parseHeaders(headers);
  return { ssns: data, totalCount };
};

interface ITransactionResponse {
  transactions: ITransactionReadView[];
  totalCount: number;
}

export const fetchCustomerApplications = async (
  id: string,
  config: AxiosRequestConfig
): Promise<ISnabbApplicationsResponse> => {
  config.paramsSerializer = { indexes: null };
  const { data, headers } = await http.get<ILoanApplicationSnabbReadView[]>(
    `${CustomersUrl}/${id}/applications`,
    config
  );
  const { totalCount } = parseHeaders(headers);
  return { applications: data, totalCount };
};

export const fetchCustomerTransactions = async (
  id: string,
  config?: AxiosRequestConfig
): Promise<ITransactionResponse> => {
  const { data, headers } = await http.get<ITransactionReadView[]>(`${CustomersUrl}/${id}/transactions`, config);
  const { totalCount } = parseHeaders(headers);
  return { transactions: data, totalCount };
};

export const fetchCustomerPDF = async (id: string): Promise<DownloadFileResponse> => {
  const { data, headers } = await http.get<Blob>(`${CustomersUrl}/${id}.pdf`, {
    headers: { Accept: 'application/pdf' },
    responseType: 'blob'
  });
  const filename = getFileNameFromHeaders(headers);
  return { data, filename };
};

export const getMyPagesToken = async (id: number): Promise<string> => {
  const { data } = await http.get<string>(`${CustomersUrl}/${id}/my-pages-token`);
  return data;
};

export const fetchCustomerAuthenticationLatest = async (
  id: number,
  config?: AxiosRequestConfig
): Promise<CustomerAuthentication> => {
  const { data } = await http.get<CustomerAuthentication>(`${CustomersUrl}/${id}/authentications/latest`, config);
  return data;
};

export const fetchCustomerAutogiroMandates = async (
  id: string,
  config?: AxiosRequestConfig
): Promise<AutogiroMandate[]> => {
  const { data } = await http.get<AutogiroMandate[]>(`${CustomersUrl}/${id}/autogiro-mandates`, config);
  return data;
};

export const deleteCustomerAutogiroMandate = async (customerId: Number, mandateId: Number): Promise<HttpStatusCode> => {
  const { status } = await http.delete(`${CustomersUrl}/${customerId}/autogiro-mandates/${mandateId}`);
  return status;
};

export const fetchAutogiroMandateAsPDF = async (mandateId: Number): Promise<DownloadFileResponse> => {
  const { data, headers } = await http.get<Blob>(`${AutogiroFilesUrl}/mandate/${mandateId}`, {
    headers: { Accept: 'application/pdf' },
    responseType: 'blob'
  });
  const filename = getFileNameFromHeaders(headers);
  return { data, filename };
};
