import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Image, Menu, SemanticICONS } from 'semantic-ui-react';
import daypayLogo from '../utils/images/logo-daypay-white.png';
import { GlobalContext } from '../Context/GlobalState';
import GlobalSearch from '../utils/GlobalSearch';

export interface IMenuItemObject {
  to: string;
  content: string;
  icon: SemanticICONS;
  key: string;
  children?: IDropdownMenuItemObject[];
}
interface IDropdownMenuItemObject {
  to: string;
  content: string;
  key: string;
}

const reportsItems: IDropdownMenuItemObject[] = [
  { to: 'debt-collections-overview', content: 'Inkasso-översikt', key: 'debt-collections-overview' },
  { to: 'quarterly-report', content: 'Kvartalsrapport', key: 'quarterly-report' },
  { to: 'customers-interest-expenses', content: 'KU25 Ränteutgifter', key: 'customers-interest-expenses' },
  { to: 'fals', content: 'FI FALS', key: 'fals' },
  { to: 'credit-check', content: 'Kreditupplysning', key: 'credit-check' }
];

const menuItems: IMenuItemObject[] = [
  { to: '/applications', content: 'Lånlista', icon: 'file alternate', key: 'applications' },
  { to: '/payments', content: 'Betalningar', icon: 'exchange', key: 'payments' },
  { to: '/block-list', content: 'Spärrlistor', icon: 'ban', key: 'block-list' },
  { to: '/messages', content: 'Meddelanden', icon: 'comment', key: 'messages' },
  { to: '/help', content: 'Hjälp', icon: 'help circle', key: 'help' },
  { to: '/reports', content: 'Rapporter', icon: 'tasks', key: 'reports', children: reportsItems }
];

const NavBar: React.FC = () => {
  const context: React.ContextType<typeof GlobalContext> = React.useContext(GlobalContext);

  return (
    <Menu inverted color="grey" style={{ marginTop: 0 }}>
      <Menu.Item as={NavLink} to="/" header={true}>
        <Image src={daypayLogo} size="tiny" />
      </Menu.Item>
      {menuItems.map((item) => {
        return !item.children ? (
          <Menu.Item as={NavLink} icon={item.icon} to={item.to} content={item.content} key={item.key} />
        ) : (
          <Dropdown item text={item.content} key={item.key}>
            <Dropdown.Menu>
              {item.children.map((child) => (
                <Dropdown.Item as={NavLink} to={`${item.to}/${child.to}`} content={child.content} key={child.key} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      })}
      <Menu.Menu position="right">
        <Menu.Item>
          <GlobalSearch />
        </Menu.Item>
        <Menu.Item as={NavLink} icon="cog" content="Inställningar" to="/settings" />
        <Menu.Item icon="log out" content="Logga ut" onClick={context.handleLogOut!} />
      </Menu.Menu>
    </Menu>
  );
};

export default NavBar;
