export const downloadBlob = (fileName: string, file: Blob) => {
  const downloadLink = document.createElement('a');
  downloadLink.target = '_blank';
  downloadLink.download = fileName;
  const downloadUrl = window.URL.createObjectURL(file);
  downloadLink.href = downloadUrl;
  document.body.append(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadUrl);
};

export const getFileNameFromHeaders = (headers: any): string => {
  let filename = '';
  const disposition = headers['content-disposition'] !== null ? (headers['content-disposition'] as string) : null;
  if (disposition && disposition.includes('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};
