import { BasicSortOption, IBasicTableFilters, defaultLimit, PaginationFilters } from '../TableActions/DefaultValues';
import { ApplicationsSortOptions, ICreditsafeReadView, LoanStatusDisplay } from '../Applications/Interfaces';
import { AutogiroMandate } from '../Payments/Autogiro/AutogiroInterfaces';

export interface ICustomerReadView {
  id: number;
  ssn: string;
  bankAccount: string;
  fullName: string;
  gender: string;
  birthday: number;
  phone: string;
  email: string;
  createdDate: number;
  blocked: boolean;
  blockedSnabb: boolean;
  creditsafeData?: ICreditsafeReadView;
  autogiroMandates: AutogiroMandate[];
  markedForDeletion: number;
}

export interface BankAccount {
  bankAccountDisplay: string;
  bankAccountNo: string;
  bankClearingNo: string;
}

export interface CustomerUpdateView {
  bankAccountNo: string;
  bankClearingNo: string;
  phone: string;
  email: string;
}

export type CustomerSortOptions = BasicSortOption | 'firstName' | 'lastName' | 'email' | 'phone';

export interface ICustomerTableFilters extends IBasicTableFilters {
  sort: CustomerSortOptions;
}

export interface ITransactionInvoiceFilters {
  offset: number;
  limit: number;
}

export const customerDefaultTableFilters: ICustomerTableFilters = {
  sortAscending: false,
  sort: 'id',
  offset: 0,
  limit: defaultLimit,
  filter: ''
};

export interface CustomerApplicationsFilter extends PaginationFilters {
  sortAscending: boolean;
  sort: ApplicationsSortOptions;
  statuses?: LoanStatusDisplay[];
}

export const defaultCustomerApplicationsFilter: CustomerApplicationsFilter = {
  sortAscending: false,
  sort: 'createdOrApplied',
  limit: 3,
  offset: 0
};

export type TransactionType = 'LOAN_PAYOUT' | 'LOAN_REPAYMENT' | 'ARVATO' | 'OTHER';

export const TransactionTypeMessage: Record<TransactionType, string> = {
  ARVATO: 'Arvato',
  LOAN_PAYOUT: 'Utbetalning',
  LOAN_REPAYMENT: 'Inbetalning',
  OTHER: 'Övrigt'
} as const;

export const TransactionTypeSign: Record<TransactionType, string> = {
  LOAN_PAYOUT: '-',
  LOAN_REPAYMENT: '+',
  OTHER: '+',
  ARVATO: ''
} as const;

export interface ITransactionReadView {
  amount: number;
  applicationId: number;
  date: number;
  painTransactionId: number;
  paymentRecordId: number;
  incomingIsoTransactionId: number;
  text: string;
  type: TransactionType;
}

export interface SSNReadView {
  id: number;
  ssn: string;
}
export interface CustomerAuthentication {
  timeStamp: number;
  verificationCode: number;
}
