import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import NavBar from './NavBar/NavBar';
import { Container } from 'semantic-ui-react';
import { GlobalContext } from './Context/GlobalState';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './Routes/AppRoutes';
import ComponentLoader from './Loaders/ComponentLoader';
import Footer from './Footer/Footer';

const Login = lazy(() => import('./Auth/Login'));

const App: React.FC = () => {
  const context: React.ContextType<typeof GlobalContext> = React.useContext(GlobalContext);
  return (
    <BrowserRouter>
      <Container fluid={true} className="main-container">
        {!context.isAuthenticated ? (
          <div className="login-container">
            <Suspense fallback={<ComponentLoader />}>
              <Routes>
                <Route index path="/" element={<Login />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </div>
        ) : (
          <>
            <NavBar />
            <Suspense fallback={<ComponentLoader />}>
              <div className="app-container">
                <AppRoutes />
              </div>
            </Suspense>
          </>
        )}
        <Footer />
      </Container>
      <ToastContainer autoClose={3000} position="bottom-center" draggable={false} />
    </BrowserRouter>
  );
};

export default App;
