export const REACT_APP_API_ENDPOINT =
  process.env.NODE_ENV === 'production' ? window.config.REACT_APP_API_ENDPOINT : 'http://127.0.0.1:8080/api';
export const REACT_APP_OAUTH_TOKEN_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? window.config.REACT_APP_OAUTH_TOKEN_ENDPOINT
    : 'http://127.0.0.1:8080/oauth2/token';
export const REACT_APP_LOGIN_URL =
  process.env.NODE_ENV === 'production' ? window.config.REACT_APP_LOGIN_URL : 'http://127.0.0.1:3000/login';
export const REACT_APP_INTERNAL_URL =
  process.env.NODE_ENV === 'production' ? window.config.REACT_APP_INTERNAL_URL : 'http://127.0.0.1:3002';
export const REACT_APP_CUSTOMER_PORTAL_BASE_URL =
  process.env.NODE_ENV === 'production' ? window.config.REACT_APP_CUSTOMER_PORTAL_BASE_URL : 'http://daypay.localhost';
export const REACT_APP_SCOPE = 'all';
export const REACT_APP_CLIENT_ID = 'clientid';
export const REACT_APP_CLIENT_SECRET = 'secret';
export const REACT_APP_VERSION = process.env.NODE_ENV === 'production' ? window.config.REACT_APP_VERSION : 'local';
