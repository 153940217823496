// Code split by lazy-loading components
import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import ApplicationsFiltersContext from '../Applications/ApplicationsFiltersContext';

const Users = lazy(() => import('../Users/Users'));
const CustomerView = lazy(() => import('../Customers/CustomerView/CustomerView'));
const CustomerHistoryView = lazy(() => import('../Customers/CustomerView/CustomerHistoryView'));
const Applications = lazy(() => import('../Applications/Applications'));
const ApplicationView = lazy(() => import('../Applications/ApplicationView/ApplicationView'));
const CreditCheck = lazy(() => import('../CreditCheck/CreditCheck'));
const Messages = lazy(() => import('../Messages/Messages'));
const PaymentsIndex = lazy(() => import('../Payments/PaymentsIndex'));
const IncomingPayments = lazy(() => import('../Payments/IncomingPayments'));
const IncomingTransactions = lazy(() => import('../Payments/IncomingTransactionsView'));
const IncomingIsoPayments = lazy(() => import('../Payments/IncomingIsoPayments'));
const IncomingIsoTransactions = lazy(() => import('../Payments/IncomingIsoTransactionsView'));
const OutgoingPayments = lazy(() => import('../Payments/OutgoingPayments'));
const OutgoingTransactions = lazy(() => import('../Payments/OutgoingTransactionsView'));
const AutogiroIndex = lazy(() => import('../Payments/Autogiro/AutogiroIndex'));
const AutogiroFiles = lazy(() => import('../Payments/Autogiro/AutogiroFiles'));
const AutogiroMandateAdviceView = lazy(() => import('../Payments/Autogiro/AutogiroMandateAdviceView'));
const AutogiroCancelledPaymentsView = lazy(() => import('../Payments/Autogiro/AutogiroCancelledPaymentsView'));
const AutogiroRejectedPaymentsView = lazy(() => import('../Payments/Autogiro/AutogiroRejectedPaymentsView'));
const AutogiroPaymentsSpecificationView = lazy(() => import('../Payments/Autogiro/AutogiroPaymentsSpecificationView'));
const OutgoingAutogiroFiles = lazy(() => import('../Payments/Autogiro/OutgoingAutogiroFiles'));
const OutgoingAutogiroFileView = lazy(() => import('../Payments/Autogiro/OutgoingAutogiroFileView'));
const CustomersInterestExpenses = lazy(() => import('../Reports/InterestExpenses'));
const DebtCollectionsOverview = lazy(() => import('../Reports/DebtCollectionsOverview'));
const QuarterlyReport = lazy(() => import('../Reports/QuarterlyReport'));
const FalsSurveyIndex = lazy(() => import('../Reports/FalsSurveyIndex'));
const AggregatedSurvey = lazy(() => import('../Reports/AggregatedSurvey'));
const SampleSurvey = lazy(() => import('../Reports/SampleSurvey'));
const VerificationIndex = lazy(() => import('../Verification/VerificationIndex'));
const VerificationGeneral = lazy(() => import('../Verification/VerificationGeneral'));
const Consumer = lazy(() => import('../Verification/Consumer'));
const Help = lazy(() => import('../Help/Help'));
const InvoiceView = lazy(() => import('../Invoices/InvoiceView'));
const EstimatedExpenses = lazy(() => import('../Settings/EstimatedExpenses'));
const InvoiceSettings = lazy(() => import('../Settings/InvoiceSettings'));
const SettingsIndex = lazy(() => import('../Settings/SettingsIndex'));
const TermsSettings = lazy(() => import('../Settings/TermsSettings'));
const ExternalServicesSettings = lazy(() => import('../Settings/ExternalServicesSettings'));
const BlockListView = lazy(() => import('../BlockRegistry/BlockRegistryView'));

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/" element={<Navigate to="/applications" />} />
      <Route path="/applications" element={<ApplicationsFiltersContext />}>
        <Route index element={<Applications />} />
        <Route path="view/:id" element={<ApplicationView />} />
      </Route>
      <Route path="/customer/:id" element={<CustomerView />} />
      <Route path="/customer/:id/history" element={<CustomerHistoryView />} />
      <Route path="/payments" element={<PaymentsIndex />}>
        <Route index element={<Navigate to="incoming" />} />
        <Route path="incoming" element={<IncomingIsoPayments />} />
        <Route path="incoming/:id" element={<IncomingIsoTransactions />} />
        <Route path="outgoing" element={<OutgoingPayments />} />
        <Route path="outgoing/:id" element={<OutgoingTransactions />} />
        <Route path="manual-uploads" element={<IncomingPayments />} />
        <Route path="manual-uploads/:id" element={<IncomingTransactions />} />
        <Route path="autogiro" element={<AutogiroIndex />}>
          <Route index element={<Navigate to="files/incoming" />} />
          <Route path="files/incoming" element={<AutogiroFiles />} />
          <Route path="files/incoming/mandate_advice/:id" element={<AutogiroMandateAdviceView />} />
          <Route path="files/incoming/cancelled_payments/:id" element={<AutogiroCancelledPaymentsView />} />
          <Route path="files/incoming/rejected_payments/:id" element={<AutogiroRejectedPaymentsView />} />
          <Route path="files/incoming/payments_specification/:id" element={<AutogiroPaymentsSpecificationView />} />
          <Route path="files/outgoing" element={<OutgoingAutogiroFiles />} />
          <Route path="files/outgoing/:id" element={<OutgoingAutogiroFileView />} />
        </Route>
      </Route>
      <Route path="/messages" element={<Messages />} />
      <Route path="/reports/debt-collections-overview" element={<DebtCollectionsOverview />} />
      <Route path="/reports/customers-interest-expenses" element={<CustomersInterestExpenses />} />
      <Route path="/reports/credit-check" element={<CreditCheck />} />
      <Route path="/reports/quarterly-report" element={<QuarterlyReport />} />
      <Route path="/reports/fals" element={<FalsSurveyIndex />}>
        <Route index element={<Navigate to="aggregated-survey" />} />
        <Route path="aggregated-survey" element={<AggregatedSurvey />} />
        <Route path="sample-survey" element={<SampleSurvey />} />
      </Route>
      <Route path="/help" element={<Help />} />
      <Route path="/invoice/:id" element={<InvoiceView />} />
      <Route path="/settings" element={<SettingsIndex />}>
        <Route index element={<Navigate to="application/general" />} />
        <Route path="application" element={<VerificationIndex />}>
          <Route path="general" element={<VerificationGeneral />} />
          <Route path="consumer" element={<Consumer />} />
          <Route path="estimated-expenses" element={<EstimatedExpenses />} />
        </Route>
        <Route path="users" element={<Users />} />
        <Route path="invoice" element={<InvoiceSettings />} />
        <Route path="terms" element={<TermsSettings />} />
        <Route path="external-services" element={<ExternalServicesSettings />} />
        <Route path="external-services/fortnox-oauth/*" element={<ExternalServicesSettings />} />
      </Route>
      <Route path="block-list" element={<BlockListView />} />
    </Routes>
  );
};

export default AppRoutes;
