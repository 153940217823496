import React, { useContext, ContextType } from 'react';
import { REACT_APP_VERSION } from '../config';
import { http } from '../http/Http';
import { ApiEndpoints } from '../http/ApiEndpoints';
import { GlobalContext } from '../Context/GlobalState';
import { Icon, Menu } from 'semantic-ui-react';

const getServerVersion = async (): Promise<string> => {
  const { data } = await http.get<string>(ApiEndpoints.versionController);
  return data;
};

const Footer: React.FC<{}> = () => {
  const context: ContextType<typeof GlobalContext> = useContext(GlobalContext);
  const [serverVersion, setServerVersion] = React.useState<string>('');
  React.useEffect(() => {
    (async () => {
      try {
        const data = await getServerVersion();
        setServerVersion(data);
      } catch (error) {
        setServerVersion('N/A');
      }
    })();
  }, [context.isAuthenticated]);
  return (
    <Menu inverted size="mini" fixed="bottom">
      <Menu.Item>
        <Icon name="user" />
        {context.currentUser?.firstName} {context.currentUser?.lastName}
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item>
          <Icon name="computer" />
          {REACT_APP_VERSION.match(/\d+/g) ? REACT_APP_VERSION.slice(0, 10) + `...` : REACT_APP_VERSION}
        </Menu.Item>
        <Menu.Item>
          <Icon name="database" />
          {serverVersion.match(/\d+/g) ? serverVersion.slice(0, 10) + `...` : serverVersion}
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default Footer;
