import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import {
  REACT_APP_API_ENDPOINT,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_OAUTH_TOKEN_ENDPOINT
} from '../config';
import { queryString } from '../utils/query-string';

const headers = {
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest'
};

const toBase64 = window.btoa(`${REACT_APP_CLIENT_ID}:${REACT_APP_CLIENT_SECRET}`);
export const baseAuthHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: `Basic ${toBase64}`
};

export enum HttpStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Ok = 200,
  Created = 201
}

export interface IAuthResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders;
}

const injectToken = (config: AdaptAxiosRequestConfig): AdaptAxiosRequestConfig => {
  const token = localStorage.getItem('token');
  if (token != null) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return config;
};

const baseURL = `${REACT_APP_API_ENDPOINT}/admin/v1`;

const HandleAuthError = (error: AxiosError): any => {
  const refreshToken = localStorage.getItem('refresh_token');
  if (refreshToken && error.config && error.response && error.response.status === HttpStatusCode.Unauthorized) {
    return axios
      .post<IAuthResponse>(
        REACT_APP_OAUTH_TOKEN_ENDPOINT!,
        queryString({
          grant_type: 'refresh_token',
          refresh_token: refreshToken
        }),
        { headers: baseAuthHeader }
      )
      .then(({ data }) => {
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        error.config!.headers!.Authorization = `Bearer ${data.access_token}`;
        return axios.request(error.config!);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    return Promise.reject(error);
  }
};

class Http {
  private HTTP: AxiosInstance | null = null;
  private reqInterceptId: number | null = null;
  private resInterceptId: number | null = null;
  private get http(): AxiosInstance {
    return this.HTTP ? this.HTTP : this.initHttp();
  }
  public initHttp() {
    const axiosHttp = axios.create({ baseURL, headers });
    this.reqInterceptId = axiosHttp.interceptors.request.use(injectToken, (error) => Promise.reject(error));
    this.resInterceptId = axiosHttp.interceptors.response.use(
      (response: AxiosResponse<any>) => response,
      HandleAuthError
    );
    this.HTTP = axiosHttp;
    return axiosHttp;
  }

  public request(config: AxiosRequestConfig) {
    return this.http.request(config);
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.http.get<T>(url, config);
  }

  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.post<T>(url, data, config);
  }

  public delete(url: string, config?: AxiosRequestConfig) {
    return this.http.delete(url, config);
  }

  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.put<T>(url, data, config);
  }

  public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.http.patch<T>(url, data, config);
  }
}

export const http = new Http();
export const CancelToken = axios.CancelToken;
export const isCancel = axios.isCancel;
