import { ICustomerReadView } from '../Customers/Interfaces';
import {
  Gender,
  BasicSortOption,
  IBasicTableFilters,
  defaultLimit,
  IDropDownOption
} from '../TableActions/DefaultValues';
import { HttpStatusCode } from '../http/Http';
import { SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { OutgoingTransaction } from '../Payments/Interfaces';

export type ApplicationsSortOptions =
  | BasicSortOption
  | 'createdOrApplied'
  | 'customer.firstName'
  | 'loanAmount'
  | 'status'
  | 'payOffDate';

export interface IApplicationsTableFilters extends IBasicTableFilters {
  status: LoanStatusDisplay;
  sort: ApplicationsSortOptions;
  showArchived: boolean;
  needsMandate: boolean;
}

export const applicationsDefaultTableFilters: IApplicationsTableFilters = {
  sortAscending: false,
  sort: 'createdOrApplied',
  offset: 0,
  limit: defaultLimit,
  filter: '',
  status: 'PENDING',
  showArchived: false,
  needsMandate: false
};
export type LoanStatus =
  | 'OFFERED'
  | 'NEEDS_REVIEW'
  | 'PENDING'
  | 'REGISTERED'
  | 'AWAITING_PAYOUT'
  | 'PAYED'
  | 'INVOICED'
  | 'CANCELLED'
  | 'REMINDER'
  | 'INKASSO'
  | 'LOCKED'
  | 'PAID_OFF'
  | 'REJECTED';

export type LoanStatusDisplay =
  | 'NONE'
  | 'NEEDS_REVIEW'
  | 'OFFERED'
  | 'PENDING'
  | 'REGISTERED'
  | 'AWAITING_PAYOUT'
  | 'PAYED'
  | 'INVOICED'
  | 'CANCELLED'
  | 'REMINDER'
  | 'INKASSO'
  | 'INKASSO_KRONOFOGDEN'
  | 'INKASSO_SOLD'
  | 'LOCKED'
  | 'PAID_OFF'
  | 'REJECTED';

export type LoanStatusType =
  | 'INKASSO__REMINDER'
  | 'INKASSO__KRONOFOGDEN'
  | 'INKASSO__SOLD'
  | 'LOCKED__INKASSO'
  | 'LOCKED__DECLINED'
  | 'LOCKED__AG_CANCELED'
  | 'LOCKED__BLOCKED_YOURSELF'
  | 'LOCKED__VICTIM_OF_FRAUD'
  | 'LOCKED__BLOCKED_BANK_CODE' /* Should not be in UI */
  | 'LOCKED__FRAUD_BANK_ACCOUNT'
  | 'LOCKED__OTHER';

export const LoanStatusMessage: Record<LoanStatus, string> = {
  OFFERED: 'Erbjudande',
  NEEDS_REVIEW: 'Manuell granskning',
  PENDING: 'Inkommande',
  REGISTERED: 'Registrerad',
  AWAITING_PAYOUT: 'Inväntar utbetalning',
  PAYED: 'Utbetalad',
  INVOICED: 'Fakturerad',
  CANCELLED: 'Makulerade',
  REMINDER: 'Påminnelse',
  INKASSO: 'Inkasso',
  LOCKED: 'Spärrad',
  PAID_OFF: 'Återbetalad',
  REJECTED: 'Avslag'
} as const;

export const LoanStatusDisplayMessages: Record<LoanStatusDisplay, string> = {
  NONE: 'none',
  OFFERED: 'Erbjudande',
  NEEDS_REVIEW: 'Manuell granskning',
  PENDING: 'Inkommande',
  REGISTERED: 'Registrerad',
  AWAITING_PAYOUT: 'Inväntar utbetalning',
  PAYED: 'Utbetalning',
  INVOICED: 'Fakturerad',
  CANCELLED: 'Makulerade',
  REMINDER: 'Påminnelse',
  INKASSO: 'Inkasso',
  INKASSO_KRONOFOGDEN: 'Inkasso (Kronofogden) ',
  INKASSO_SOLD: 'Inkasso (Såld)',
  LOCKED: 'Spärrad',
  PAID_OFF: 'Återbetalad',
  REJECTED: 'Avslag'
} as const;

export const LoanStatusDisplayMessagesMinified: Record<LoanStatusDisplay, string> = {
  NONE: 'none',
  OFFERED: 'Erbjudande',
  NEEDS_REVIEW: 'Manuell granskning',
  PENDING: 'Inkommande',
  REGISTERED: 'Registrerad',
  AWAITING_PAYOUT: 'Inväntar utbetalning',
  PAYED: 'Utbetalning',
  INVOICED: 'Fakturerad',
  CANCELLED: 'Makulerade',
  REMINDER: 'Påminnelse',
  INKASSO: 'Inkasso',
  INKASSO_KRONOFOGDEN: 'Kronofogden',
  INKASSO_SOLD: 'Såld',
  LOCKED: 'Spärrad',
  PAID_OFF: 'Återbetalad',
  REJECTED: 'Avslag'
} as const;

export const generateOptionsFromStatusFilters = (list: IStats[]): IDropDownOption[] => {
  const statusOptions: IDropDownOption[] = [];
  list.forEach((element) => {
    statusOptions.push({ value: element.status, text: LoanStatusDisplayMessages[element.status] });
  });
  return statusOptions;
};

export const statusesForDropdown: IDropDownOption[] = [
  { value: 'OFFERED', text: LoanStatusDisplayMessages['OFFERED'] },
  { value: 'NEEDS_REVIEW', text: LoanStatusDisplayMessages['NEEDS_REVIEW'] },
  { value: 'PENDING', text: LoanStatusDisplayMessages['PENDING'] },
  { value: 'REGISTERED', text: LoanStatusDisplayMessages['REGISTERED'] },
  { value: 'AWAITING_PAYOUT', text: LoanStatusDisplayMessages['AWAITING_PAYOUT'] },
  { value: 'PAYED', text: LoanStatusDisplayMessages['PAYED'] },
  { value: 'INVOICED', text: LoanStatusDisplayMessages['INVOICED'] },
  { value: 'CANCELLED', text: LoanStatusDisplayMessages['CANCELLED'] },
  { value: 'REMINDER', text: LoanStatusDisplayMessages['REMINDER'] },
  { value: 'INKASSO', text: LoanStatusDisplayMessages['INKASSO'] },
  { value: 'INKASSO_SOLD', text: LoanStatusDisplayMessages['INKASSO_SOLD'] },
  { value: 'INKASSO_KRONOFOGDEN', text: LoanStatusDisplayMessages['INKASSO_KRONOFOGDEN'] },
  { value: 'LOCKED', text: LoanStatusDisplayMessages['LOCKED'] },
  { value: 'PAID_OFF', text: LoanStatusDisplayMessages['PAID_OFF'] },
  { value: 'REJECTED', text: LoanStatusDisplayMessages['REJECTED'] }
];

export const LoanStatusColor: Record<LoanStatus, SemanticCOLORS> = {
  OFFERED: 'yellow',
  NEEDS_REVIEW: 'purple',
  PENDING: 'purple',
  REGISTERED: 'blue',
  AWAITING_PAYOUT: 'blue',
  PAYED: 'blue',
  INVOICED: 'teal',
  PAID_OFF: 'green',
  REMINDER: 'orange',
  INKASSO: 'red',
  REJECTED: 'red',
  LOCKED: 'grey',
  CANCELLED: 'grey'
};

export const LoanStatusIcon: Record<LoanStatus, SemanticICONS> = {
  OFFERED: 'question',
  NEEDS_REVIEW: 'search',
  PENDING: 'search',
  REGISTERED: 'clipboard check',
  AWAITING_PAYOUT: 'wait',
  PAYED: 'exchange',
  INVOICED: 'file alternate',
  CANCELLED: 'trash',
  REMINDER: 'bell',
  INKASSO: 'warning sign',
  PAID_OFF: 'checkmark',
  REJECTED: 'times',
  LOCKED: 'lock'
};

export type IActionType =
  | 'FETCH_DATA'
  | 'PARSE_DATA'
  | 'UPDATE_DB'
  | 'IMPORT'
  | 'LOAN_OFFER'
  | 'CHECK_LOAN_HISTORY'
  | 'CHECK_MAX_ACTIVE_LOANS_PLUS'
  | 'CHECK_MAX_ACTIVE_LOANS_SNABB'
  | 'CHECK_MAX_ACTIVE_LOANS_SNABB_WEB'
  | 'CHECK_INKASSO'
  | 'CHECK_BLOCKED_CUSTOMER'
  | 'CHECK_LOCKED_APPLICATION'
  | 'CHECK_MIN_AGE'
  | 'CHECK_SCORING'
  | 'CHECK_DEBT_SUM'
  | 'CHECK_INCOME_TAXABLE'
  | 'CHECK_MAX_LOAN_VALUE'
  | 'FORTNOX_INVOICE'
  | 'ARVATO_ADD_TRANSACTION'
  | 'CHANGE_STATUS'
  | 'CHANGE_STATUS_TO_CANCELLED'
  | 'CHANGE_STATUS_TO_INKASSO'
  | 'CHANGE_STATUS_TO_PAYED'
  | 'MANUAL_REVIEW'
  | 'MANUAL_REJECTION'
  | 'LOAN_REPAYMENT'
  | 'ARCHIVE'
  | 'SEND_SMS'
  | 'RECEIVED_SMS'
  | 'CHECK_OFFER_EXPIRATION_DATE'
  | 'CHECK_CREDIT_REGISTRY_STATUS'
  | 'CHECK_BANK_CODE'
  | 'CHECK_BLOCKED_BANK_CODES'
  | 'CHECK_BLOCKED_BANK_ACCOUNTS'
  | 'CHECK_BLOCKED_PERSONAL_NUMBERS'
  | 'CHECK_PHONE_NOT_IN_USE'
  | 'COPY'
  | 'CHECK_INKASSO_PROBABILITY'
  | 'CHECK_CONSUMER_AGENCY_INFO'
  | 'CHECK_LOAN_REPAYMENT_AMOUNT'
  | 'CHECK_PLUS_LOAN_AMOUNT'
  | 'CHECK_PLUS_UC'
  | 'CHECK_PLUS_ARVATO'
  | 'ARVATO_COMMUNICATION'
  | 'FORTNOX_COMMUNICATION'
  | 'CHECK_EU_SANCTIONS_LIST'
  | 'CREDIT_LIMIT_INCREASE_REQUESTED'
  | 'CREDIT_LIMIT_INCREASE_VERIFICATION'
  | 'CREDIT_LIMIT_INCREASE_APPROVED'
  | 'CREDIT_LIMIT_INCREASE_REJECTED'
  | 'CHECK_CUSTOMER_AGE_FOR_REVIEW'
  | 'CHECK_SURETY_TOTAL'
  | 'CHECK_PERCENT_HOUSEHOLD_EXPENSES';

export type IActionStatus = 'OK' | 'OK_WITH_WARNING' | 'FAILED' | 'NEEDS_REVIEW';

export const ActionTypeMessage: Record<IActionType, string> = {
  FETCH_DATA: 'Hämta data',
  PARSE_DATA: 'Bearbeta data',
  UPDATE_DB: 'Uppdatera databas',
  IMPORT: 'Importera',
  LOAN_OFFER: 'Lånerbjudande',
  CHECK_LOAN_HISTORY: 'Kontrollera lånhistorik',
  CHECK_MAX_ACTIVE_LOANS_PLUS: 'Kontrollera tillåtet antal pluslån',
  CHECK_MAX_ACTIVE_LOANS_SNABB: 'Kontrollera tillåtet antal lån',
  CHECK_MAX_ACTIVE_LOANS_SNABB_WEB: 'Kontrollera tillåtet antal lån, webb',
  CHECK_INKASSO: 'Kontrollera inkasso',
  CHECK_BLOCKED_CUSTOMER: 'Kontrollera spärr, kund',
  CHECK_LOCKED_APPLICATION: 'Kontrollera låsta ansökningar',
  CHECK_MIN_AGE: 'Kontrollera lägst tillåten ålder',
  CHECK_SCORING: 'Kontrollera scoring',
  CHECK_DEBT_SUM: 'Kontrollera skuld',
  CHECK_INCOME_TAXABLE: 'Kontrollera taxeringsbar inkomst',
  CHECK_MAX_LOAN_VALUE: 'Kontrollera max tillåten lånsumma',
  FORTNOX_INVOICE: 'Fortnox, fakturering',
  ARVATO_ADD_TRANSACTION: 'Arvato, skapa transaktion',
  CHANGE_STATUS: 'Ändra status',
  CHANGE_STATUS_TO_CANCELLED: 'Ändra status till Makulerad',
  CHANGE_STATUS_TO_INKASSO: 'Ändra status till Inkasso',
  CHANGE_STATUS_TO_PAYED: 'Ändra status till Utbetalad',
  MANUAL_REVIEW: 'Manuell granskning',
  MANUAL_REJECTION: 'Manuellt avslag',
  LOAN_REPAYMENT: 'Återbetalning av lån',
  ARCHIVE: 'Arkivering',
  SEND_SMS: 'Skicka SMS',
  RECEIVED_SMS: 'Inkommande SMS',
  CHECK_OFFER_EXPIRATION_DATE: 'Kontrollera giltlighetstid av erbjudande',
  CHECK_CREDIT_REGISTRY_STATUS: 'Kontrollera status hos kreditregister',
  CHECK_BANK_CODE: 'Kontrollera bankkod',
  CHECK_BLOCKED_BANK_CODES: 'Kontrollera spärr, bankkod',
  CHECK_BLOCKED_BANK_ACCOUNTS: 'Kontrollera spärr, bankkontonummer',
  CHECK_BLOCKED_PERSONAL_NUMBERS: 'Kontrollera spärr, personnummer',
  CHECK_PHONE_NOT_IN_USE: 'Kontrollera att telefonnummer inte redan används',
  COPY: 'Kopiera',
  CHECK_INKASSO_PROBABILITY: 'Kontrollera sannolikhet för inkasso',
  CHECK_CONSUMER_AGENCY_INFO: 'Kontrollera Kvar att leva på (KALP)',
  CHECK_LOAN_REPAYMENT_AMOUNT: 'Kontrollera återbetalning av lånsumma',
  CHECK_PLUS_LOAN_AMOUNT: 'Kontrollera lånsumma (plus)',
  CHECK_PLUS_UC: 'Kontrollera UC (plus)',
  CHECK_PLUS_ARVATO: 'Kontrollera Arvato (plus)',
  ARVATO_COMMUNICATION: 'Arvato, kommunikation',
  FORTNOX_COMMUNICATION: 'Fortnox, kommunikation',
  CHECK_EU_SANCTIONS_LIST: 'Kontrollera EU:s sanktionslista',
  CREDIT_LIMIT_INCREASE_REQUESTED: 'Kreditgräns: begärd höjning',
  CREDIT_LIMIT_INCREASE_VERIFICATION: 'Kreditgräns: verifierad höjning',
  CREDIT_LIMIT_INCREASE_APPROVED: 'Kreditgräns: godkänd höjning',
  CREDIT_LIMIT_INCREASE_REJECTED: 'Kreditgräns: avslagen höjning',
  CHECK_CUSTOMER_AGE_FOR_REVIEW: 'Kontrollera om kundens ålder är över 74 år',
  CHECK_SURETY_TOTAL: 'Kontrollera om kunden har borgensåtaganden',
  CHECK_PERCENT_HOUSEHOLD_EXPENSES: 'Kontrollera om andel av hushållets utgifter är 25% eller lägre'
};

export interface IApplicationActionReadView {
  createdDate: number;
  actionType: IActionType;
  actionStatus: IActionStatus;
  description: string;
}

export interface IBankAccountReadView {
  bankClearingNo: string;
  bankAccountNo: string;
  bankAccountDisplay: string;
}
export interface ChildInfo {
  age: number;
  gender: Gender;
  numberOfCaretakers: number;
}

export type IOfferType = 'NONE' | 'SMS_FIRST' | 'SMS_SECOND' | 'SMS_THIRD' | 'WEB';

export const OfferTypeMessage: Record<IOfferType, string> = {
  NONE: 'Ny ansökan',
  SMS_FIRST: 'SMS: Erbjudande',
  SMS_SECOND: 'SMS: Extra',
  SMS_THIRD: 'SMS: Sista chansen',
  WEB: 'Webberbjudande'
};

export const LoanStatusFromLoanStatusDisplay: Record<LoanStatusDisplay, LoanStatus> = {
  NONE: 'PENDING',
  OFFERED: 'OFFERED',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  AWAITING_PAYOUT: 'AWAITING_PAYOUT',
  PAYED: 'PAYED',
  INVOICED: 'INVOICED',
  CANCELLED: 'CANCELLED',
  REMINDER: 'REMINDER',
  INKASSO: 'INKASSO',
  INKASSO_SOLD: 'INKASSO',
  INKASSO_KRONOFOGDEN: 'INKASSO',
  LOCKED: 'LOCKED',
  PAID_OFF: 'PAID_OFF',
  REJECTED: 'REJECTED'
};

export const LoanStatusTypeMapToLoanStatus: Record<LoanStatusType, LoanStatus> = {
  INKASSO__REMINDER: 'INKASSO',
  INKASSO__KRONOFOGDEN: 'INKASSO',
  INKASSO__SOLD: 'INKASSO',
  LOCKED__INKASSO: 'LOCKED',
  LOCKED__DECLINED: 'LOCKED',
  LOCKED__AG_CANCELED: 'LOCKED',
  LOCKED__BLOCKED_YOURSELF: 'LOCKED',
  LOCKED__VICTIM_OF_FRAUD: 'LOCKED',
  LOCKED__FRAUD_BANK_ACCOUNT: 'LOCKED',
  LOCKED__OTHER: 'LOCKED',
  LOCKED__BLOCKED_BANK_CODE: 'LOCKED' /* should not be in UI */
};

type SelectableLoanStatusType = Exclude<LoanStatusType, 'LOCKED__BLOCKED_BANK_CODE'>;

export const LoanStatusTypeMessage: Record<SelectableLoanStatusType, string> = {
  INKASSO__REMINDER: 'Efter påminnelse',
  INKASSO__KRONOFOGDEN: 'Kronofogden',
  INKASSO__SOLD: 'Såld till tredje part',
  LOCKED__INKASSO: 'Inkasso',
  LOCKED__DECLINED: 'Avvisad',
  LOCKED__AG_CANCELED: 'Medgivandet är makulerat',
  LOCKED__BLOCKED_YOURSELF: 'Spärrat sig själv',
  LOCKED__VICTIM_OF_FRAUD: 'Utsatt för bedrägeri (övriga)',
  LOCKED__FRAUD_BANK_ACCOUNT: 'Utsatt för bedrägeri (banknummer)',
  LOCKED__OTHER: ' Övriga'
};

export const StatusOptionsMapToStatusType: Record<LoanStatus, SelectableLoanStatusType[]> = {
  INKASSO: ['INKASSO__REMINDER', 'INKASSO__KRONOFOGDEN', 'INKASSO__SOLD'],
  LOCKED: [
    'LOCKED__INKASSO',
    'LOCKED__DECLINED',
    'LOCKED__AG_CANCELED',
    'LOCKED__BLOCKED_YOURSELF',
    'LOCKED__VICTIM_OF_FRAUD',
    'LOCKED__FRAUD_BANK_ACCOUNT',
    'LOCKED__OTHER'
  ],
  CANCELLED: [],
  INVOICED: [],
  OFFERED: [],
  PAID_OFF: [],
  PAYED: [],
  PENDING: [],
  NEEDS_REVIEW: [],
  REGISTERED: [],
  AWAITING_PAYOUT: [],
  REJECTED: [],
  REMINDER: []
};

export interface Review {
  decision: 'YES' | 'NO';
  reason?: IActionType | null;
  rationale?: DecisionRationale | null;
}

export interface ReviewSubmission {
  manualReviews: Review[];
  note: string;
}

export const ActionTypeAsReason: Partial<Record<IActionType, string>> = {
  CHECK_CUSTOMER_AGE_FOR_REVIEW: 'Kundens ålder är över 75 år',
  CHECK_SURETY_TOTAL: 'Kunden har borgensåtaganden',
  MANUAL_REJECTION: 'Manuellt avslag',
  CHECK_PERCENT_HOUSEHOLD_EXPENSES: 'Kunden har angett en låg andel av hushållets gemensamma utgifter'
};

export type DecisionRationale =
  | 'SUSPECTED_FRAUD'
  | 'OKAY'
  | 'CUSTOMER_REQUESTED'
  | 'INCORRECT_DETAILS'
  | 'HAS_DEPENDENT_THIRD_PARTY'
  | 'NET_INCOME_MARGIN_UNCERTAIN';

export const DecisionRationaleMessage: Record<DecisionRationale, string> = {
  OKAY: 'OK',
  SUSPECTED_FRAUD: 'Misstanke om bedrägeri',
  CUSTOMER_REQUESTED: 'På begäran av kund',
  INCORRECT_DETAILS: 'Felaktiga uppgifter',
  HAS_DEPENDENT_THIRD_PARTY: 'Tredje part är i beroendeställning',
  NET_INCOME_MARGIN_UNCERTAIN: 'Osäker marginal efter beräkning av utgifter'
};

export const DecisionRationalesByActionType: Partial<Record<IActionType, DecisionRationale[]>> = {
  CHECK_CUSTOMER_AGE_FOR_REVIEW: ['CUSTOMER_REQUESTED', 'SUSPECTED_FRAUD', 'OKAY'],
  CHECK_SURETY_TOTAL: ['CUSTOMER_REQUESTED', 'HAS_DEPENDENT_THIRD_PARTY', 'INCORRECT_DETAILS'],
  MANUAL_REJECTION: ['CUSTOMER_REQUESTED', 'INCORRECT_DETAILS'],
  CHECK_PERCENT_HOUSEHOLD_EXPENSES: ['CUSTOMER_REQUESTED', 'NET_INCOME_MARGIN_UNCERTAIN', 'INCORRECT_DETAILS']
};

export const LoanStatusTransitionSet: Record<LoanStatus, LoanStatus[]> = {
  OFFERED: ['OFFERED', 'REJECTED'],
  NEEDS_REVIEW: ['NEEDS_REVIEW'],
  PENDING: ['PENDING', 'REGISTERED', 'REJECTED'],
  REGISTERED: ['REGISTERED', 'AWAITING_PAYOUT', 'REJECTED'],
  AWAITING_PAYOUT: ['AWAITING_PAYOUT', 'REJECTED'],
  PAYED: ['PAYED'],
  INVOICED: ['INVOICED', 'PAID_OFF'],
  CANCELLED: ['CANCELLED', 'PAID_OFF'],
  REMINDER: ['REMINDER', 'PAID_OFF'],
  INKASSO: ['INKASSO', 'PAID_OFF'],
  LOCKED: ['LOCKED', 'PAID_OFF'],
  PAID_OFF: ['PAID_OFF'],
  REJECTED: ['REJECTED']
};

export interface IApplicationUpdateView {
  loanStatus: LoanStatus;
  loanStatusType: LoanStatusType | null;
  comment: string;
}

export interface IApplicationMultipleUpdateView extends IApplicationUpdateView {
  ids: number[];
}

export enum ITypeOfLiving {
  'VILLA',
  'BOSTADSRATT',
  'OTHER',
  'UNKNOWN'
}

export type EmploymentStatus = 'EMPLOYED' | 'UNEMPLOYED' | 'STUDENT' | 'RETIRED';

export const EmploymentStatusLabel: Record<EmploymentStatus, string> = {
  EMPLOYED: 'Anställd',
  UNEMPLOYED: 'Arbetslös',
  STUDENT: 'Student',
  RETIRED: 'Pensionär'
};

export type EmploymentType =
  | 'PERMANENT'
  | 'SELF_EMPLOYED'
  | 'TRIAL_EMPLOYMENT'
  | 'TEMPORARY_EMPLOYMENT'
  | 'SUBSTITUTE_EMPLOYMENT'
  | 'SEASONAL_EMPLOYMENT';

export const EmploymentTypeLabel: Record<EmploymentType, string> = {
  PERMANENT: 'Fast anställning / Tillsvidareanställning',
  SELF_EMPLOYED: 'Egenföretagare',
  TRIAL_EMPLOYMENT: 'Provanställning',
  TEMPORARY_EMPLOYMENT: 'Allmän visstid',
  SUBSTITUTE_EMPLOYMENT: 'Vikariat',
  SEASONAL_EMPLOYMENT: 'Säsongsanställning'
};

export interface ICreditsafeReadView {
  firstName: string;
  givenName: string;
  lastName: string;
  coAddress: string;
  registeredAddress: string;
  zipCode: string;
  city: string;
  municipality: string;
  relationSsn: string;
  relationName: string;
  ropSum: number; // record of non-payment
  ropNumber: number; // record of non-payment
  debtSum: number;
  debtNum: number;
  debtDate: number;
  isBankrupt: boolean;
  debtAMALSum: number; // debt from "allmänna mål"
  debtAMALNumber: number; // number of "allmänna mål"
  debtEMALSum: number; // debt from "enskilda mål"
  debtEMALNumber: number; // number of "enskilda mål"
  isDebtPerson: boolean; // skuldsanering
  score: number;
  income: number; // income from work
  commonReduce: number;
  incomeTaxable: number;
  incomeCapital: number;
  deficitCapital: number;
  incomeTotal: number;
  finalTax: number;
  investigate: boolean;
  taxYear: number;
  dateLastAMAL: number;
  dateLastEMAL: number;
  realEstateNum: number;
  buildingsNum: number;
  assessedValueTotal: number;
  assessedValueBuilding: number;
  searchDate: number; // when data was fetched from external service
  income2: number;
  incomeTotal2: number;
  finalTax2: number;
}

export type KalpCalculationItemCategory =
  | 'MONTHLY_INCOME'
  | 'EXPENSE_TEMPLATE_ESTIMATES'
  | 'OTHER_EXPENSES'
  | 'LIVING_COSTS'
  | 'KALP_CALCULATION'
  | 'TOTAL_COSTS_CALCULATION'
  | 'REPAYMENT_CALCULATION';

export type KalpCalculationItemName =
  | 'APPLICATION_INCOME'
  | 'APPLICATION_HOUSING_EXPENSES'
  | 'CREDIT_CHECK_INCOME'
  | 'ALLOWABLE_INCOME_INCREASE_FACTOR'
  | 'ADJUSTED_CREDIT_CHECK_INCOME'
  | 'EMPLOYMENT_TYPE'
  | 'EMPLOYMENT_STATUS'
  | 'INCOME_WE_USE'
  | 'LOAN_REPAYMENT_AMOUNT'
  | 'APPLICATION_DEBT_EXPENSES'
  | 'APPLICATION_OTHER_EXPENSES'
  | 'TOTAL_LIVING_EXPENSES'
  | 'HOUSEHOLD_PEOPLE_EXPENSES'
  | 'KALP'
  | 'TOTAL_CHILD_EXPENSES'
  | 'ADULT_FOOD_EXPENSES'
  | 'CHILD_FOOD_EXPENSE'
  | 'TOTAL_ADULT_EXPENSES'
  | 'ADULT_OTHER_EXPENSES'
  | 'CHILD_OTHER_EXPENSE'
  | 'TOTAL_ESTIMATED_LIVING_EXPENSES'
  | 'ADJUSTED_ADULT_EXPENSES'
  | 'APPLICATION_HOUSEHOLD_EXPENSES'
  | 'HOUSEHOLD_EXPENSES_USED'
  | 'TOTAL_LOAN_REPAYMENT_AMOUNT'
  | 'TOTAL_EXPENSES'
  | 'PERCENT_SHARE_OF_EXPENSES';

export const KalpCalculationItemNameLabel: Partial<Record<KalpCalculationItemName, string>> = {
  KALP: 'Kvar att leva på',
  TOTAL_EXPENSES: 'Totala utgifter',
  TOTAL_LIVING_EXPENSES: 'Totala levnadsomkostnader',
  HOUSEHOLD_EXPENSES_USED: 'Hushållets utgifter',
  APPLICATION_HOUSEHOLD_EXPENSES: 'Hushållets utgifter enligt ansökan',
  TOTAL_ESTIMATED_LIVING_EXPENSES: 'Hushållets utgifter enligt uppskattning',
  HOUSEHOLD_PEOPLE_EXPENSES: 'Hushållets gemensamma utgifter givet antal personer',
  ADJUSTED_ADULT_EXPENSES: 'Justerade utgifter för vuxna',
  TOTAL_ADULT_EXPENSES: 'Utgifter för vuxna',
  ADULT_FOOD_EXPENSES: 'Matkostnader för vuxna',
  ADULT_OTHER_EXPENSES: 'Övriga kostnader för vuxna',
  PERCENT_SHARE_OF_EXPENSES: 'Ansökarens andel av hushållets gemensamma utgifter',
  TOTAL_CHILD_EXPENSES: 'Utgifter för barn',
  CHILD_FOOD_EXPENSE: 'Matkostnader för enskilt barn',
  CHILD_OTHER_EXPENSE: 'Övriga kostnader för enskilt barn',
  APPLICATION_OTHER_EXPENSES: 'Övriga utgifter',
  APPLICATION_HOUSING_EXPENSES: 'Boendekostnader',
  TOTAL_LOAN_REPAYMENT_AMOUNT: 'Totala lånkostnader',
  LOAN_REPAYMENT_AMOUNT: 'Återbetalning av lån',
  APPLICATION_DEBT_EXPENSES: 'Lånkostnader hos andra långivare enligt ansökan',
  INCOME_WE_USE: 'Inkomst',
  APPLICATION_INCOME: 'Inkomst i ansökan',
  CREDIT_CHECK_INCOME: 'Inkomst i kreditupplysning',
  ALLOWABLE_INCOME_INCREASE_FACTOR: 'Tillåten inkomstökning',
  ADJUSTED_CREDIT_CHECK_INCOME: 'Justerad inkomst i kreditupplysning',
  EMPLOYMENT_STATUS: 'Sysselsättning',
  EMPLOYMENT_TYPE: 'Anställningsform'
};

export type KalpCalculationItemSource =
  | 'EXPENSE_TEMPLATE'
  | 'APPLICATION'
  | 'CALCULATED'
  | 'CALCULATED_SUM'
  | 'CALCULATED_COMPARISON_MIN'
  | 'CALCULATED_COMPARISON_MAX'
  | 'CALCULATED_MULTIPLICATION'
  | 'CREDIT_CHECK'
  | 'MULTI_FACTOR_REASONING'
  | 'DAYPAY_SYSTEM';

export const KalpCalculationItemSourceLabel: Record<KalpCalculationItemSource, string> = {
  APPLICATION: 'Ansökan',
  CALCULATED: 'Beräkning',
  CALCULATED_SUM: 'Summering',
  CALCULATED_COMPARISON_MAX: 'Högst värde',
  CALCULATED_COMPARISON_MIN: 'Lägst värde',
  CALCULATED_MULTIPLICATION: 'Multiplicering',
  CREDIT_CHECK: 'Kreditupplysning',
  DAYPAY_SYSTEM: 'Daypay System',
  EXPENSE_TEMPLATE: 'Schablon',
  MULTI_FACTOR_REASONING: 'Sammanvägd bedömning'
};

export const KalpCalculationItemSourceIcon: Record<KalpCalculationItemSource, SemanticICONS> = {
  APPLICATION: 'user',
  CALCULATED: 'calculator',
  CALCULATED_COMPARISON_MAX: 'sort numeric up',
  CALCULATED_COMPARISON_MIN: 'sort numeric down',
  CALCULATED_SUM: 'sitemap',
  CALCULATED_MULTIPLICATION: 'asterisk',
  CREDIT_CHECK: 'clipboard',
  DAYPAY_SYSTEM: 'server',
  EXPENSE_TEMPLATE: 'book',
  MULTI_FACTOR_REASONING: 'code branch'
};

export type KalpCalculationItemValueType = 'NUMBER_KRONOR' | 'NUMBER_PERCENT' | 'NUMBER' | 'ENUM';

export const KalpCalculationItemValueEnumLabels: Record<EmploymentStatus | EmploymentType, string> = {
  ...EmploymentStatusLabel,
  ...EmploymentTypeLabel
};

export interface KalpCalculationItem {
  id: number;
  category: KalpCalculationItemCategory;
  value: number | EmploymentStatus | EmploymentType;
  valueType: KalpCalculationItemValueType;
  itemName: KalpCalculationItemName;
  note: string;
  source: KalpCalculationItemSource;
  sourceItems: KalpCalculationItemName[];
  summaryId: number;
}

export interface KalpCalculationItemWithHistory extends KalpCalculationItem {
  history?: KalpCalculationItemWithHistory[];
}

export interface KalpCalculationSummary {
  items: KalpCalculationItem[];
  netIncome: number;
  note: string;
}

export interface ILoanApplicationReadView {
  id: number;
  loanAmount: number;
  bankAccount: IBankAccountReadView;
  loanStatusDisplay: LoanStatusDisplay;
  customer: ICustomerReadView;
  phone: string;
  email: string;
  newCustomer: boolean;
  kalpCalculatorVersion: number;
  kalpCalculationSummary: KalpCalculationSummary;
  childrenUnder18: number;
  childrenUnder18Info: ChildInfo[];
  archived: boolean;
  offeredRef: ILoanApplicationSnabbReadView | null;
  applicationActions: IApplicationActionReadView[];
  loanPeriod: number;
  employmentStatus: EmploymentStatus;
  employmentType: EmploymentType;
  endOfEmployment: number;
  monthlyIncome: number;
  householdPeopleNum: number;
  loanExpensesAmount: number;
  debtExpensesAmount: number;
  shareOfHouseholdExpenses: number;
  percentHouseholdExpenses: number;
  houseExpensesAmount: number;
  otherExpenses: number;
  suretyTotal: number;
  loanPurpose: string;
  typeOfLiving: ITypeOfLiving;
  ipAddress: string;
  createdOrApplied: number;
  applied: number;
  creditsafeData: ICreditsafeReadView | null;
  returnRequestedDate: number;
  cancelledAutogiro: boolean;
  transactions: OutgoingTransaction[];
}

export interface ILoanApplicationSnabbReadView extends ILoanApplicationReadView {
  payOffDate: number;
  offerType: IOfferType;
  rejectedRef: ILoanApplicationReadView | null;
  offerReplyText: string;
  loanStatus: LoanStatus;
  loanStatusType: LoanStatusType;
}

export interface IStats {
  archived: number;
  count: number;
  status: LoanStatusDisplay;
}
export interface ILoanStatusCountStatistics {
  incomingSnabbLoan: boolean;
  loanStatusCountStatistics: IStats[];
}

export interface ISnabbApplicationsResponse {
  applications: ILoanApplicationSnabbReadView[];
  totalCount: number;
}

export interface ILoanApplicationCloneOverrides {
  applied: number;
  payOffDate: number;
  loanAmount: number;
  email: string;
  phone: string;
  bankClearingNo: string;
  bankAccountNo: string;
}
export interface ILoanApplicationCloneOverridesUpdateView extends ILoanApplicationCloneOverrides {}

export interface LoanApplicationCloneResultReadView {
  loanApplicationSnabb: ILoanApplicationSnabbReadView;
  verificationResults: IApplicationActionReadView[];
}

export interface CloneResult {
  status: HttpStatusCode;
  data: LoanApplicationCloneResultReadView;
}

export const INCOMING_SET: LoanStatusDisplay[] = ['PENDING', 'NEEDS_REVIEW', 'REJECTED'];
export const ONGOING_SET: LoanStatusDisplay[] = [
  'REGISTERED',
  'AWAITING_PAYOUT',
  'PAYED',
  'INVOICED',
  'REMINDER',
  'INKASSO',
  'INKASSO_SOLD'
];
export const ARCHIVABLE_STATUS: LoanStatusDisplay[] = ['REJECTED', 'PAID_OFF'];
export const TOP_STATUS_FILTER: LoanStatusDisplay[] = [
  'REJECTED',
  'OFFERED',
  'NEEDS_REVIEW',
  'PENDING',
  'REGISTERED',
  'AWAITING_PAYOUT',
  'PAYED',
  'INVOICED',
  'REMINDER',
  'INKASSO',
  'INKASSO_SOLD',
  'PAID_OFF'
];
