import { ApiEndpoints } from '../http/ApiEndpoints';
import { AxiosRequestConfig } from 'axios';
import { http, HttpStatusCode } from '../http/Http';

import {
  ILoanApplicationSnabbReadView,
  ISnabbApplicationsResponse,
  ILoanStatusCountStatistics,
  IStats,
  IApplicationUpdateView,
  IApplicationMultipleUpdateView,
  ILoanApplicationCloneOverridesUpdateView,
  CloneResult,
  LoanApplicationCloneResultReadView,
  ReviewSubmission
} from './Interfaces';
import { parseHeaders } from '../Context/GlobalState';
import { DownloadFileResponse } from '../Reports/Interfaces';
import { getFileNameFromHeaders } from '../utils/FileDownloader';
import { IInvoiceReadView } from '../Invoices/Interfaces';

const { SnabbloanUrl, SnabbloanStatusStatistics, Invoices } = ApiEndpoints;

export const fetchApplications = async (config?: AxiosRequestConfig): Promise<ISnabbApplicationsResponse> => {
  const { data, headers } = await http.get<ILoanApplicationSnabbReadView[]>(SnabbloanUrl, config);
  const { totalCount } = parseHeaders(headers);
  return { applications: data, totalCount };
};

export const fetchApplication = async (
  id: string,
  config?: AxiosRequestConfig
): Promise<ILoanApplicationSnabbReadView> => {
  const { data } = await http.get<ILoanApplicationSnabbReadView>(`${SnabbloanUrl}/${id}`, config);
  return data;
};

export const fetchStatusStatistics = async (config?: AxiosRequestConfig): Promise<IStats[]> => {
  const {
    data: { loanStatusCountStatistics }
  } = await http.get<ILoanStatusCountStatistics>(SnabbloanStatusStatistics, config);
  return loanStatusCountStatistics;
};

export const fetchApplicationPDF = async (id: string): Promise<DownloadFileResponse> => {
  const { data, headers } = await http.get<Blob>(`${SnabbloanUrl}/${id}.pdf`, {
    headers: { Accept: 'application/pdf' },
    responseType: 'blob'
  });
  const filename = getFileNameFromHeaders(headers);
  return { data, filename };
};

export const cloneApplicationById = async (
  id: number,
  view: ILoanApplicationCloneOverridesUpdateView,
  config?: AxiosRequestConfig
): Promise<CloneResult> => {
  const { data, status } = await http.post<LoanApplicationCloneResultReadView>(
    `${SnabbloanUrl}/${id}/actions/clone`,
    view,
    config
  );
  return { data, status };
};

export const fetchAgreementByApplication = async (id: number): Promise<DownloadFileResponse> => {
  const { data, headers } = await http.get<Blob>(`${SnabbloanUrl}/credit-agreement/${id}.pdf`, {
    responseType: 'blob'
  });
  const filename = getFileNameFromHeaders(headers);
  return { data, filename };
};

export const registerOneApplication = async (id: number): Promise<HttpStatusCode> => {
  const { status } = await http.post<string>(`${SnabbloanUrl}/${id}/actions/register`);
  return status;
};

export const registerMultiApplications = async (ids: number[]): Promise<HttpStatusCode> => {
  const { status } = await http.post<number[]>(`${SnabbloanUrl}/actions/register`, ids);
  return status;
};

export const submitApplicationReview = async (id: number, data: ReviewSubmission): Promise<HttpStatusCode> => {
  const { status } = await http.post<ReviewSubmission>(`${SnabbloanUrl}/${id}/actions/review`, data);
  return status;
};

export const editOneApplication = async (id: number, data: IApplicationUpdateView): Promise<HttpStatusCode> => {
  const { status } = await http.patch<IApplicationUpdateView>(`${SnabbloanUrl}/${id}`, data);
  return status;
};

export const editMultiApplications = async (data: IApplicationMultipleUpdateView): Promise<HttpStatusCode> => {
  const { status } = await http.patch<IApplicationMultipleUpdateView>(`${SnabbloanUrl}`, data);
  return status;
};

export const archiveMultiApplications = async (ids: number[]): Promise<HttpStatusCode> => {
  const { status } = await http.post<number[]>(`${SnabbloanUrl}/actions/archive`, ids);
  return status;
};

export const unArchiveMultiApplications = async (ids: number[]): Promise<HttpStatusCode> => {
  const { status } = await http.post<number[]>(`${SnabbloanUrl}/actions/restore`, ids);
  return status;
};

export const bulkPayoutRegistered = async (): Promise<HttpStatusCode> => {
  const { status } = await http.post(`${SnabbloanUrl}/actions/bulk-move-registered-into-payout`);
  return status;
};

export const invoiceMultiWithFortnox = async (ids: number[]): Promise<HttpStatusCode> => {
  const { status } = await http.post<number[]>(`${SnabbloanUrl}/actions/invoice`, ids);
  return status;
};

export const exportLBInvoiceData = async (ids: number[]): Promise<DownloadFileResponse> => {
  const params = new URLSearchParams();
  ids.forEach((id) => params.append('snabbLoanIds', String(id)));
  const { data, headers } = await http.get<Blob>(`${SnabbloanUrl}-lb`, { params });
  const filename = getFileNameFromHeaders(headers);
  return { data, filename };
};

export const fetchInvoices = async (config?: AxiosRequestConfig): Promise<IInvoiceReadView[]> => {
  const { data } = await http.get<IInvoiceReadView[]>(Invoices, config);
  return data;
};

export const fetchApplicationsInvoices = async (ids: number[], config: AxiosRequestConfig): Promise<any> => {
  let temp: { [key: string]: IInvoiceReadView[] } | null = null;
  await Promise.all(
    ids.map((id) =>
      fetchInvoices({ params: { applicationId: id }, cancelToken: config.cancelToken }).then((data) => {
        const sorted = data.sort((a, b) => b.invoicedDate - a.invoicedDate);
        temp = { ...temp, [id]: sorted };
      })
    )
  );
  return temp;
};

export const flagReturn = async (id: number, date: { dateOfRequest: Date }): Promise<HttpStatusCode> => {
  const { status } = await http.post<string>(`${SnabbloanUrl}/${id}/actions/flag-return`, date);
  return status;
};

export const cancelLoanPaymentInitiations = async (id: number): Promise<HttpStatusCode> => {
  const { status } = await http.post<string>(`${SnabbloanUrl}/${id}/actions/cancel-ag-payment`);
  return status;
};
