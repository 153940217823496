import { SemanticCOLORS } from 'semantic-ui-react';
import { AutogiroPaymentInitiation } from '../Payments/Autogiro/AutogiroInterfaces';

export interface FortnoxVoucher {
  fortnoxVoucherNumber: number;
  fortnoxVoucherSeries: string;
  fortnoxVoucherYear: number;
}

export interface InvoiceArticle {
  account: number;
  created: number;
  id: number;
  name: string;
  originalInvoiceId: number | null;
  price: number;
  fortnoxVoucher: FortnoxVoucher | null;
}

export interface IInvoiceReadView {
  amount: number;
  applicationId: number;
  balance: number;
  customerId: number;
  dueDate: number;
  earlyRepaymentAmount: number;
  id: number;
  /**
   * @deprecated Invoices are no longer managed by Fortnox.
   */
  fortnoxInvoiceNumber: number | null;
  invoiceStatus: 'PAID' | 'NOT_PAID' | 'DISREGARD' | string;
  invoicedDate: number;
  ocr: string;
  type: 'DEFAULT' | 'REMINDER';
  paymentInitiation: AutogiroPaymentInitiation;
  articles: InvoiceArticle[];
}

export const InvoiceTypeMessage: Record<'DEFAULT' | 'REMINDER', string> = {
  DEFAULT: 'Faktura',
  REMINDER: 'Påminnelsefaktura'
};

export const InvoiceStatusMessage: Record<IInvoiceReadView['invoiceStatus'], string> = {
  PAID: 'Slutbetald',
  NOT_PAID: 'Ej betald',
  DISREGARD: 'Makulerad'
};

export const InvoiceStatusColor: Record<IInvoiceReadView['invoiceStatus'], SemanticCOLORS> = {
  PAID: 'green',
  NOT_PAID: 'yellow',
  DISREGARD: 'red'
};
