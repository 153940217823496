import React from 'react';
import { AxiosError } from 'axios';
import { HttpStatusCode, IAuthResponse } from '../http/Http';
import { toast } from 'react-toastify';
import { queryString } from '../utils/query-string';
import {
  REACT_APP_SCOPE,
  REACT_APP_OAUTH_TOKEN_ENDPOINT,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_API_ENDPOINT,
  REACT_APP_INTERNAL_URL
} from '../config';
import differenceInHours from 'date-fns/differenceInHours';
import { IUser } from '../Users/UsersService';

// Globla types that might be used in many components
interface IHeaders {
  totalCount: number;
}
export const parseHeaders = (headers: any): IHeaders => {
  const totalCount: number = headers['x-total-count'] != null ? parseInt(headers['x-total-count'], 10) : 0;
  return { totalCount };
};
export interface IErrors {
  [key: string]: { content: string; pointing?: string };
}

export const isEmail = (value: string): boolean =>
  value.search(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) >= 0;

export const isPhoneNumber = (value: string): boolean => value.search(/^[+]?46[0-9]{7,10}$/) >= 0;

interface IProps {
  children: any;
}

type AuthErrorData = {
  error: string;
};

interface ResponseError {
  code: string;
  message: string;
}

type ResponseErrorData = {
  errors: ResponseError[];
};

export type recentlyInvoicedType = { timeStamp: number; ids: number[] };

export interface IGlobalContextProps {
  currentUser: IUser | null;
  isAuthenticated: boolean;
  handleLogin: ((authenticationCode: string) => Promise<HttpStatusCode>) | null;
  /** cleans local storage and sets auth state to false */
  handleLogOut: (() => void) | null;
  /** Populates a toast with error message
   * For Auth errors try to refresh token first
   * else cleans localStorage and logges out
   */
  handleResponseError: ((error: AxiosError) => void) | null;
  recentlyInvoiced: recentlyInvoicedType | null;
  handleRecentlyInvoiced: (ids: number[]) => void;
}

const initialContext: IGlobalContextProps = {
  currentUser: null,
  isAuthenticated: false,
  handleLogin: null,
  handleLogOut: null,
  handleResponseError: null,
  recentlyInvoiced: null,
  handleRecentlyInvoiced: () => {}
};
const checkAuth = (): boolean => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refresh_token');
  const user = localStorage.getItem('current_user');
  return token !== null && refreshToken !== null && user !== null;
};

export const GlobalContext = React.createContext<IGlobalContextProps>(initialContext);

const GlobalState: React.FC<IProps> = (props: IProps) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(checkAuth);
  const [currentUser, setCurrentUser] = React.useState<IUser | null>(null);
  const [recentlyInvoiced, setRecentlyInvoiced] = React.useState<recentlyInvoicedType | null>(null);

  React.useEffect(() => {
    let mounted: boolean = true;
    let retrievedData = localStorage.getItem('recentlyInvoiced');
    if (retrievedData) {
      const parsed: recentlyInvoicedType = JSON.parse(retrievedData);
      if (differenceInHours(Date.now(), parsed.timeStamp) >= 12) {
        localStorage.removeItem('recentlyInvoiced');
        if (mounted) setRecentlyInvoiced(null);
      } else {
        if (mounted) setRecentlyInvoiced(parsed);
      }
    }
    let storedUser = localStorage.getItem('current_user');
    if (storedUser) {
      try {
        let object = JSON.parse(storedUser);
        if (object && typeof object === 'object') {
          setCurrentUser(object as IUser);
        }
      } catch (error: any) {
        handleLogOut();
      }
    }
    return () => {
      mounted = false;
    };
  }, []);

  const isAuthError = (value: unknown): value is AuthErrorData => {
    return (value as AuthErrorData).error === 'invalid_grant';
  };

  const isResponseError = (value: unknown): value is ResponseErrorData => {
    return (value as ResponseErrorData).errors !== undefined;
  };

  const handleResponseError = (error: AxiosError) => {
    const { response } = error;
    if (response) {
      if (response.status === HttpStatusCode.Unauthorized) {
        handleLogOut();
      }
      if (isAuthError(response.data)) {
        toast.info('Inloggningen gick inte att bekräfta. Logga in igen!');
        handleLogOut();
      } else if (isResponseError(response.data)) {
        if (response.data.errors.length > 0) {
          response.data.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
    } else if (error.code !== AxiosError.ERR_CANCELED) {
      toast.error(`${error.code}: ${error.message}`);
    }
  };

  const handleLogin = async (authenticationCode: string): Promise<HttpStatusCode> => {
    try {
      const tokenRequestBody = {
        code: authenticationCode,
        grant_type: 'authorization_code',
        scope: REACT_APP_SCOPE,
        state: 'Authorization',
        redirect_uri: `${REACT_APP_INTERNAL_URL}`
      };

      const tokenResponse = await fetch(REACT_APP_OAUTH_TOKEN_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${window.btoa(`${REACT_APP_CLIENT_ID}:${REACT_APP_CLIENT_SECRET}`)}`
        },
        body: queryString(tokenRequestBody)
      });

      if (tokenResponse.ok) {
        const result: IAuthResponse = JSON.parse(await tokenResponse.text());

        if (result.access_token && result.refresh_token) {
          localStorage.setItem('token', result.access_token);
          localStorage.setItem('refresh_token', result.refresh_token);

          await fetch(`${REACT_APP_API_ENDPOINT}/admin/v1/users/current`, {
            headers: {
              Authorization: `Bearer ${result.access_token}`
            }
          })
            .then(async (response) => JSON.parse(await response.text()) as IUser)
            .then((result: IUser) => {
              localStorage.setItem('current_user', JSON.stringify(result));
              setCurrentUser(result);
              setIsAuthenticated(true);
              return HttpStatusCode.Ok;
            });
        }
      } else {
        throw new Error(`${tokenResponse.status} ${tokenResponse.statusText}`);
      }
    } catch (error: any) {
      toast.error(`Authorization error: ${error.message}`);
      return HttpStatusCode.BadRequest;
    }
    return HttpStatusCode.Unauthorized;
  };

  const handleLogOut = () => {
    setIsAuthenticated(false);
    setCurrentUser(null);
    localStorage.clear();
  };

  const handleRecentlyInvoiced = (ids: number[]) => {
    const oldIds: number[] = recentlyInvoiced ? recentlyInvoiced.ids : [];
    const updatedData: recentlyInvoicedType = { timeStamp: Date.now(), ids: [...oldIds, ...ids] };
    localStorage.setItem('recentlyInvoiced', JSON.stringify(updatedData));
    setRecentlyInvoiced(updatedData);
  };

  return (
    <GlobalContext.Provider
      value={{
        currentUser,
        isAuthenticated,
        handleLogin: handleLogin,
        handleLogOut,
        handleResponseError,
        recentlyInvoiced,
        handleRecentlyInvoiced
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
